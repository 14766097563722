import { ButtonProps } from 'types';
import Image from 'next/legacy/image';
import { Spinner } from 'components/Spinner';

import cx from 'classnames';
import styles from './Button.module.scss';

export const Button = ({
  action,
  buttonType = 'blue',
  buttonHeight = '',
  caption,
  className,
  disabled = false,
  fit = true,
  iconSrc,
  iconOnly = false,
  id,
  shape = 'wide',
  withoutBorder,
  withSpinner = false,
}: ButtonProps) => {
  return (
    <button
      id={id}
      className={cx(
        styles.default_button,
        styles[`${buttonType}`],
        styles[`${buttonHeight}`],
        styles[`${shape}`],
        {
          [styles.fit]: iconSrc && fit,
          [styles.iconOnly]: iconOnly,
          [styles.withoutBorder]: withoutBorder,
        },
        className,
      )}
      onClick={action}
      disabled={disabled}
    >
      {withSpinner && <Spinner className={styles.buttonSpinner} />}
      {iconSrc ? <Image alt="Plus Circle" width={24} height={24} src={iconSrc} /> : null}
      {caption ? <p className={cx(styles.content, { [styles.indent]: iconSrc })}>{caption}</p> : null}
    </button>
  );
};
